<template>
  <div id="app">
    <div class="relative overflow-x-clip">
      <img class="object-cover w-full h-72 overflow-hidden" src="/assets/iStock-1148091243.jpg" alt="header image" />

      <div class="absolute top-5 left-10 right-10 flex">
        <a href="https://www.red-eng.com"><img src="/assets/red-logo-white-3.svg" class="flex-none w-48" alt="RED Engineering Design" /></a>
        <div class="flex-auto" />
        <div class="flex-none"><div class="text-white text-xl font-bold border-4 border-white rounded pl-2 pr-2 pb-1"><a href="https://www.red-eng.com/locations/">Contact</a></div></div>
      </div>

      <div class="ml-10 mr-10">
        <div class="flex flex-col lg:justify-between lg:flex-row">
          <div>
            <div class="absolute left-0 right-0">
              <div class="absolute -mt-32 pl-16 lg:pl-0 lg:-mt-24 lg:pt-2 text-white font-bold lg:ml-10">
                <span class="text-5xl">assu</span><span class="text-4xl">RED</span>
              </div>
              <div class="lg:ml-2 hidden lg:block top-0 mt-2 text-lg text-gray-700 mt-4 flex flex-col">
                <div class="flex flex-row">

                  <!-- Left Text Column -->
                  <div class="basis-3/12">
                    <div class="font-semibold flex flex-col gap-y-4">
                      <div class="flex flex-row">
                        <ChartIcon class="basis-2/12 self-center"/>
                        <h1 class="basis-10/12">Calculate data centre Power Usage Effectiveness (PUE) across different geographical locations.</h1>
                      </div>
                      <div class="flex flex-row">
                        <PowerIcon class="basis-2/12 self-center"/>
                        <h1 class="basis-10/12">Estimate the impact of Tier 2, Tier 3 and Tier 4 redundancy design on data centre energy consumption metrics.</h1>
                      </div>
                      <div class="flex flex-row">
                        <GlobeIcon class="basis-2/12 self-center"/>
                        <h1 class="basis-10/12">Developed by 
                          <a class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.red-eng.com">RED Engineering Design</a>, 
                          specialists in sustainable data centre design.</h1>
                      </div>
                    </div>

                    <div class="flex flex-col gap-y-1 mt-6">
                      <ExpandInfoTitle :isClicked="infoIsClicked('data_centre')"
                                       @onMouseEnter="handleInfoTitleMouseEnter('data_centre')"
                                       @onMouseLeave="handleInfoTitleMouseLeave('data_centre')"
                                       @onClick="handleInfoTitleClick('data_centre')"
                                       id="info-title-data-centre">
                        What is a Data Centre?
                      </ExpandInfoTitle>
                      <ExpandInfoTitle :isClicked="infoIsClicked('pue')"
                                       @onMouseEnter="handleInfoTitleMouseEnter('pue')"
                                       @onMouseLeave="handleInfoTitleMouseLeave('pue')"
                                       @onClick="handleInfoTitleClick('pue')"
                                       id="info-title-pue">
                        What is PUE?
                      </ExpandInfoTitle>
                      <ExpandInfoTitle :isClicked="infoIsClicked('assured')"
                                       @onMouseEnter="handleInfoTitleMouseEnter('assured')"
                                       @onMouseLeave="handleInfoTitleMouseLeave('assured')"
                                       @onClick="handleInfoTitleClick('assured')"
                                       id="info-title-assured">
                        What is assuRED?
                      </ExpandInfoTitle>
                    </div>
                  </div>

                  <!-- Left Text Column (should be hidden behind app window) -->
                  <div class="basis-6/12 mx-12 overflow-visible text-justify mt-2 mb-4 pr-6"
                      :class="{'transition delay-[400ms] scale-y-0 duration-0' : translateAppWindow !== true}"
                      id="info-body">
                      <ExpandInfoBody v-show="infoIsPane('data_centre')" :focused="infoIsClicked('data_centre')" id="info-body-data-centre">
                        <DataCentreInfoBody/>
                      </ExpandInfoBody>
                      <ExpandInfoBody v-show="infoIsPane('pue')"         :focused="infoIsClicked('pue')" id="info-body-pue">
                        <PueInfoBody/>
                      </ExpandInfoBody>
                      <ExpandInfoBody v-show="infoIsPane('assured')"     :focused="infoIsClicked('assured')" id="info-body-assured">
                        <AssuredInfoBody/>
                      </ExpandInfoBody>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- App Window -->
          <div class="lg:w-9/12 relative bg-white" 
            :class="{'transition translate-x-3/4 duration-200' : translateAppWindow === true,
                     'transition delay-300 translate-x-0 duration-200' : translateAppWindow !== true}">
            <div class="absolute relative -mt-16 left-0 right-0">
              <VisitorTracker/>
               <div class="bg-white p-2 rounded-lg border-primary-navy border shadow">
                 <div class="flex m-2" aria-label="Navigation">
                   <ol role="list" class="shadow px-6 flex space-x-4">
                     <li class="flex">
                       <div class="flex items-center">
                         <button
                            :class="{'text-primary-navy-650 hover:text-primary-navy-850': (step === 'select_location' || step === 'select_wmo' || step === 'show_climate' || step === 'evaluate_dc_kpi')}"
                           id="app-nav-select_location"
                           @click="step = 'select_location'"
                           >
                           <HomeIcon class="flex-shrink h-5 w-5" />
                           <span class="sr-only">Home</span>
                         </button>
                       </div>
                     </li>

                     <li class="flex">
                       <div class="flex items-center">
                         <ChevronRightIcon class="flex-shrink h-full w-6 text-primary-navy-250" 
                           :class="{'text-primary-navy-650': (step === 'select_wmo' || step === 'show_climate' || step === 'evaluate_dc_kpi')}"
                           />
                         <button
                           class="ml-4 text-sm font-medium text-primary-navy-250 hover:text-primary-navy-350"
                           :class="{'text-primary-navy-650 hover:text-primary-navy-850': (step === 'select_wmo' || step === 'show_climate' || step === 'evaluate_dc_kpi')}"
                           :disabled="location === null"
                           id="app-nav-select_wmo"
                           @click="step = 'select_wmo'"
                           >
                           WMO
                         </button>
                       </div>
                     </li>

                     <li class="flex">
                       <div class="flex items-center">
                         <ChevronRightIcon class="flex-shrink h-full w-6 text-primary-navy-250" 
                           :class="{'text-primary-navy-650': (step === 'show_climate' || step === 'evaluate_dc_kpi')}"
                           />
                         <button
                           class="ml-4 text-sm font-medium text-primary-navy-250 hover:text-primary-navy-350"
                           :class="{'text-primary-navy-650 hover:text-primary-navy-850': (step === 'show_climate' || step === 'evaluate_dc_kpi')}"
                           :disabled="location === null || ashrae_location === null"
                           id="app-nav-show_climate"
                           @click="step = 'show_climate'"
                           >
                           Climate
                         </button>
                       </div>
                     </li>

                     <li class="flex">
                       <div class="flex items-center">
                         <ChevronRightIcon class="flex-shrink h-full w-6 text-primary-navy-250" 
                           :class="{'text-primary-navy-650': (step === 'evaluate_dc_kpi')}"
                           />
                         <button
                           class="ml-4 text-sm font-medium text-primary-navy-250 hover:text-primary-navy-350"
                           :class="{'text-primary-navy-650 hover:text-primary-navy-850': (step === 'evaluate_dc_kpi')}"
                           :disabled="location === null || ashrae_location === null"
                           id="app-nav-evaluate_dc_kpi"
                           @click="step = 'evaluate_dc_kpi'"
                           >
                           DC KPIs
                         </button>
                       </div>
                     </li>
                   </ol>
                 </div>

                 <AlertWithDescription class="mt-12 mb-12 mx-4" :message="error_message" :error="error" 
                    v-if="has_error" />

                 <AlertLoading class="mt-12 mb-12 mx-4" :message="(loading !== null ? loading.message : 'Loading, please be patient')" 
                    v-if="is_loading && ! has_error" />

                 <SelectLocation 
                   :search="search"
                   @update_search="update_search($event);"
                   :locations="locations"
                   :world="world_feature_collection"
                   @on_select="step = 'select_wmo'; location = $event; ashrae_location = null;"
                   @on_error="on_error($event.message, $event.error);"
                   @on_loading="loading = $event;"
                   :class="{'hidden': is_loading || has_error}"
                   v-if="step === 'select_location'" />

                 <SelectWmo
                   :location="location"
                   :ashrae_locations="ashrae_locations"
                   :world="world_feature_collection"
                   @on_select="step = 'show_climate'; ashrae_location = $event;"
                   @on_error="on_error($event.message, $event.error);"
                   @on_loading="loading = $event;"
                   :class="{'hidden': is_loading || has_error}"
                   v-if="step === 'select_wmo' && (ashrae_locations !== null)" />

                 <ShowClimate
                   :location="ashrae_location"
                   :kgcode="location.kgcode"
                   :world="climate_feature_collection"
                   @on_next="step = 'evaluate_dc_kpi';"
                   @on_error="on_error($event.message, $event.error);"
                   @on_loading="loading = $event;"
                   :class="{'hidden': is_loading || has_error}"
                   v-if="step === 'show_climate'" />

                 <EvaluateDcKpi
                   :location="ashrae_location"
                   @on_error="on_error($event.message, $event.error);"
                   @on_loading="loading = $event;" 
                   :class="{'hidden': is_loading || has_error}"
                   v-if="step === 'evaluate_dc_kpi'" />
               </div>

               <div class="mt-6 mb-6 bg-white p-2 text-xxxs text-center text-secondary-grey rounded-md border border-primary-navy shadow-sm">
<p>Red Engineering Design Limited, herein after referred to as RED does not warrant the accuracy or completeness of the materials or the reliability of any output, advice, opinion, statement or other information as displayed or distributed through the Application or Website herein. You acknowledge that reliance on any such output, opinion, advice or statement shall be at your own/sole risk and consequences attached there to. RED reserves the right, in its sole discretion, to correct or rectify any errors or omissions in any portion of the materials. RED may make any other changes or improvements in the Applications, products or programs described/published at any time without any prior notice. All data and information provided is for the purpose of information only. This information should not be used in isolation as a basis to develop or design any building without formal validation from a qualified industry practitioner. RED makes no representations as to the accuracy, completeness, suitability, or validity of any information in the Application and will not be liable for any errors, omissions, or delays in this information or any losses, injuries, or damages arising from its display or use of the same. The content is provided for general information only, and should not be treated as a substitute for the exclusion of professional guidance.</p>
<p>Weather information sourced from NOAA</p>
<ShowVersion />
               </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import HomeIcon from "@/icons/HomeIcon.vue";
import ChevronRightIcon from "@/icons/ChevronRightIcon.vue";
import GlobeIcon from "@/icons/GlobeIcon.vue";
import PowerIcon from "@/icons/PowerIcon.vue";
import ChartIcon from "@/icons/ChartIcon.vue";

import AlertWithDescription from "@/components/AlertWithDescription.vue";
import AlertLoading from "@/components/AlertLoading.vue";
import VisitorTracker from "@/components/VisitorTracker.vue";
import EvaluateDcKpi from "@/components/EvaluateDcKpi.vue";
import SelectLocation from "@/components/SelectLocation.vue";
import SelectWmo from "@/components/SelectWmo.vue";
import ShowClimate from "@/components/ShowClimate.vue";
import ShowVersion from "@/components/ShowVersion.vue";
import ExpandInfoTitle from "@/components/ExpandInfoTitle.vue";
import ExpandInfoBody from "@/components/ExpandInfoBody.vue";

import DataCentreInfoBody from "./text/DataCentreInfoBody.vue";
import PueInfoBody from "./text/PueInfoBody.vue";
import AssuredInfoBody from "./text/AssuredInfoBody.vue";

import * as topojson from 'topojson-client';

export default {
  name: 'App',
  components: {
    HomeIcon,
    ChevronRightIcon, GlobeIcon, PowerIcon, ChartIcon,
    AlertWithDescription,
    AlertLoading,
    ShowVersion,
    EvaluateDcKpi,
    SelectLocation,
    SelectWmo,
    ShowClimate,
    VisitorTracker,
    ExpandInfoTitle,
    ExpandInfoBody,
    DataCentreInfoBody,
    PueInfoBody,
    AssuredInfoBody,
  },
  props: {
    make_locations_search_worker: {
      type: Function,
      default: () => {
        return new Worker(new URL('@/workers/locations_search.worker.js', import.meta.url), {
          type: 'module',
        });
      },
    },
  },
  data: function() {
    return {
      has_error: false,
      error_message: null,
      error: null,
      loading: false,

      step: 'select_location',
      search: '',
      locations: null,
      locations_all: null,
      locations_default: null,
      ashrae_locations: null,
      location: null,
      ashrae_location: null,
      locations_search_worker: null,

      world_feature_collection: null,
      climate_feature_collection: null,
      state: {
        pane: 'data_centre',
        clicked: false,
        show: false,
      }
    };
  },
  mounted: function() {
    // Load default locations
    fetch('/assets/locations_default.json')
    .then(r => r.json())
    .then((data) => {
      this.locations_default = data; 
      if (this.locations === null) { this.locations = this.locations_default; }
    })
    .catch((e) => {this.on_error("Error loading default locations", e);});

    // Load all locations
    this.locations_search_worker = this.make_locations_search_worker();
    this.locations_search_worker.onerror = (e) => {this.on_error("Error in locations_search_worker", e);};
    this.locations_search_worker.onmessage = (evt) => {
      try {
        if (evt.data instanceof Object) {
          this.locations = evt.data;
        } else {
          throw new Error(`Unable to parse data of type ${typeof evt.data} - ${JSON.stringify(evt.data)}`);
        }
      } catch (e) {
        this.on_error("Error when handling location search", e);
      }
    };
    
    // Load ashrae locations
    fetch('/assets/ashrae.json')
    .then(r => r.json())
    .then((data) => {this.ashrae_locations = data;})
    .catch((e) => {this.on_error("Error loading ASHRAE information", e);});

    // Load world information
    fetch('/assets/world-110m.json')
    .then(r => r.json())
    .then((data) => {
      let world = data;
      this.world_feature_collection = topojson.feature(world, world.objects.countries);
    })
    .catch((e) => {this.on_error("Error loading world map", e);});

    // Load climate map data
    fetch('/assets/1976-2000.topo.json')
    .then(r => r.json())
    .then((data) => {
      let map = data;
      this.climate_feature_collection = topojson.feature(map, map.objects.features);
    })
    .catch((e) => {this.on_error("Error loading climate map", e);});

  },
  computed: {
    is_loading: function() {
      return this.loading || 
        (this.location !== null && this.ashrae_locations === null);
    },
    translateAppWindow: function() {
      return this.state.show;
    },
  },
  methods: {
    infoIsPane: function(pane) {
      return this.state.pane === pane;
    },
    infoIsClicked: function(pane) {
      return this.state.pane === pane && this.state.clicked;
    },
    handleInfoTitleMouseEnter: function(pane) {
      if (! this.state.clicked) { this.state = {pane, clicked: false, show: true}; }
    },
    handleInfoTitleMouseLeave: function(pane) {
      if (! this.state.clicked) { this.state = {pane, clicked: false, show: false}; }
    },
    handleInfoTitleClick: function(pane) {
      if (this.state.pane === pane && this.state.clicked) {
        this.state = {pane, clicked: false, show: true};
      } else {
        this.state = {pane, clicked: true, show: true};
      }
    },
    update_search: function(v) {
      this.search = v;
      if (this.search === null || this.search.length < 3) {
        this.locations = this.locations_default;
      } else {
        this.locations_search_worker.postMessage(this.search);
      }
    },
    on_error: function(msg, e) {
      this.has_error = true;
      this.error = e; this.error_message = msg;
    }
  }
}
</script>
