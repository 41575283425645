<template>
  <div>
    v1.6.6 (Commit 3155c3b)
  </div>
</template>

<script>
export default {
  name: 'ShowVersion',
}
</script>

